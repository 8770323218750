<template>
  <div class="main-header">
    <v-navigation-drawer v-if="!$vuetify.breakpoint.mdAndUp" v-model="sideNav" :permanent="$vuetify.breakpoint.mdAndUp"
      v-bind:width="220" color="green darkar-4 green-gradient" mobile-breakpoint="960" :right="$vuetify.rtl"
      mini-variant-width="80" app id="main-sidebar" dark>
      <v-list>
        <v-list-item v-for="item in menus" :key="item.title" :to="item.link" :active-class="`primary white--text`"
          linkd>
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <div class="top-header pattern-green pattern-g-after ">
      <div class="top-header-inr d-flex justify-space-between align-center">
        <div class="header-l">
          <div class="d-flex align-center">
            <img src="../../assets/images/ri_user-line-white.svg" alt="">
            <h4 class="memberheadin ml-4">👋 {{ user.name }}</h4>
          </div>
        </div>
        <div class="herder-r d-flex align-center">
          <v-select :menu-props="{ top: false, offsetY: true }" v-model="lang" :item-value="(item) => item.lang"
            :items="items" @change="changeLanguage(lang)" solo class="custom-select language-select">
            <template v-slot:selection="{ item }">
              <img :src="item.image" width="34" />
            </template>
            <template v-slot:item="{ item }">
              <img :src="item.image" width="25" class="mr-3 mt-2 mb-2 pa-1" /><span>{{
                item.name
              }}</span>
            </template>
          </v-select>
          <div>
            <v-select class="roomSelect" v-model="room" :items="roomList" @change="changeRoom" item-text="name"
              item-value="code" :label="$t('navbar.room')" :placeholder="$t('navbar.room')" solo :dense="true"
              hide-details="auto" return-value></v-select>
          </div>
          <div class="logout-i-w ml-3" @click="doLogout">
            <div class="img-wrap"><img src="../../assets/images/logout-new.svg" alt="" class="btn-log-out-img"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { loadLanguageAsync } from "@/plugins/i18n";
import SocketMixin from "@/mixins/SocketMixin";
import { BROADCASTING } from "@/constants/services";
import { switchOn } from '@/plugins/audiobridge.js';

export default {
  name: "Navbar",
  mixins: [SocketMixin.listeners],
  data() {
    return {
      room: "",
      lang: localStorage.getItem("lang"),
      items: [
        {
          name: "English",
          lang: "en",
          image: require('@/assets/images/united-states.svg'),
        },
        {
          name: "Spanish",
          lang: "es",
          image: require('@/assets/images/spain.svg'),
        },
      ],
      sideNav: null,
      menus: [ ],
      user3Menu: [ ],
      conformDailog: false,
      link: null,
      websiteServicesLink: process.env.VUE_APP_WEBSITE_SERVICES_LINK,
      reportDialog: false,
      reportDialogMessage: "",
      reportData: null,
      inventoryUrl: process.env.VUE_APP_INVENTORY_URL,

      broadCastingService: 0,
      broadcasteNow: false,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
      userServices: "user/getUserServices",
      roomList: "members/getRoomList",
    }),
  },

  mounted() {
    this.room = localStorage.getItem("room")
      ? localStorage.getItem("room")
      : null;
    this.getRooms({ "country": this.user.user_detail.country || 'us' });
    document.getElementById("participantCount").innerText =
      sessionStorage.getItem("onlineUsers")
        ? sessionStorage.getItem("onlineUsers")
        : this.user.online_users_count;

    this.broadCastingService = this.user.services.findIndex(
      (val) => val.id === BROADCASTING
    );
  },

  methods: {
    ...mapActions({
      logout: "user/logout",
      getRooms: "members/getRoomList",
    }),

    changeRoom() {
      localStorage.setItem("room", this.room);
      location.reload();
    },

    setAvtarIcon() {
      if (this.user && this.user.user_detail) {
        if (this.user.parent_id) {
          return this.user.user_detail.representative_name.substring(0, 1);
        }
        return this.user.user_detail.company_name.substring(0, 1);
      }
      return "";
    },

    async doLogout() {
      try {
        await this.logout();
        sessionStorage.removeItem("onlineUsers");
        window.location.reload();
      } catch ({ message }) {
        console.log(message);
      }
    },
    async changeLanguage() {
      await loadLanguageAsync(this.lang);
      localStorage.setItem("lang", this.lang);
    },

    conformDailogOpen(nextLink) {
      if (this.$route.name == 'BillingInvoiceCreate') {
        this.conformDailog = true;
        this.link = nextLink;
      } else {
        this.$router.push(nextLink);
      }
    },
    goToNext() {
      this.conformDailog = false;
      this.$router.push(this.link);
    },
    onchange() {
      switchOn(this.broadcasteNow);
      if (this.broadcasteNow) {
        this.switchlabel = "Click to hang up";
      } else {
        this.switchlabel = "Click to broadcast & talk";
      }
    }
  },
};
</script>
